import { decodeHTMLString } from './decode-markdown';
import { truncateStringAtWord } from '~/utils';

export const createMetaDescription = (
    descriptions: (string | null | undefined)[]
): string => {
    const fallback =
        'Browse projects, find products, connect with professionals and learn about building, all in one place. Find everything you need to build your dream right here.';
    const description = descriptions.find((exists) => exists);

    if (!description) return fallback;
    const cleanDescription = description?.replace(/(<([^>]+)>)/gi, '');
    const maxLengthDescription = truncateStringAtWord(
        cleanDescription ?? fallback,
        160
    );

    return decodeHTMLString(maxLengthDescription);
};
