export const decodeHTMLString = (input: string | undefined): string => {
    return (
        input
            ?.replace(/&amp;/g, '&')
            .replace(/\xA0/g, ' ')
            .replace(/<br\/>/g, ' ')
            .replace(/<br>/g, ' ')
            .replace(/<b>/g, '')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&nbsp;/g, ' ')
            .replace(/<\/b>/g, '') || ''
    );
};

export const stripHTMLTags = (html: string): string => {
    if (!html) return '';
    return html.replace('<br />', ' ').replace(/<[^>]*>?/gm, '');
};
